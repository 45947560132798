.App {
  text-align: center;
}
p,
header {
  font-family: "Yanone Kaffeesatz", sans-serif;
  font-weight: 400;
  font-size: 2rem;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}
.max100px {
  max-height: 100px;
}

.container {
  margin: 3rem auto;
}
.active-scroll-spy {
  color: white !important;
  background: #000000 !important;
  border-radius: 10px;
}

@import url("https://fonts.googleapis.com/css2?family=Economica:wght@400;700&family=Open+Sans:wght@300;400;800&family=Yanone+Kaffeesatz:wght@400&display=swap");
